import { createContext, useState, useContext } from 'react';

const GameStateContext = createContext();
const UpdateGameState = createContext();

const GameState = {
    LOADING: 'loading',
    SPLASH: 'splash',
    SELECT: 'select',
    VIDEO: 'video',
    END_GAME: 'end-game',
}

const GameStateProvider = ({ children }) => {

    const [gameState, setGameState] = useState(GameState.LOADING)

    const updateGameState = (newState) => setGameState(newState)
    
    return (
        <GameStateContext.Provider value={gameState}>
            <UpdateGameState.Provider value={updateGameState}>
                {children}
            </UpdateGameState.Provider>
        </GameStateContext.Provider>)
}

const useGameState = () => {
    const context = useContext(GameStateContext);

    if (context === undefined) 
        throw new Error('useGameState must be used within the GameContextProvider');
    
    return context;
}

const useUpdateGameState = () => {
    const context = useContext(UpdateGameState);

    if (context === undefined) 
        throw new Error('useUpdateGameStatus must be used within the UpdateGameProvider');
    

    return context;
}

export {
    GameStateProvider,
    useGameState,
    useUpdateGameState,
    GameState
}