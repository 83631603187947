
import { motion } from 'framer-motion'
import { useState, useEffect } from 'react'

import ReactPlayer from 'react-player'
import { useUpdateGameState, GameState } from '../../context/game-state'
import { useSelectedCharacter } from '../../context/character-state'

const Video = () => {

    const [complete, setComplete] = useState(false)
    const [loading, setLoading] = useState(true)
    
    const updateState = useUpdateGameState();
    const characterState = useSelectedCharacter()

    useEffect(() => {
        console.log(characterState);
    })

    return (
        <>
            <div className="video">
                {!complete && (
                    <motion.div
                        animate={{
                            transition: {
                                duration: 1
                            }
                        }}
                        layoutId="selected"
                        className="video-wrapper">
                
                        {loading && (
                            <div className="loading">
                                <h2>Loading...</h2>
                            </div>
                        )}

                
                        <ReactPlayer
                            playing
                            width="100%"
                            height="100%"
                            onReady={() => setLoading(false)}
                            url={characterState.videoURL}
                            onEnded={() => setComplete(true)}
                        />

            
                    </motion.div>
                )}
            </div>
            {complete && (
                    <motion.div className="modal-container">
                        <div className="modal-wrapper">
                            <h2>You saved Christmas!</h2>
                            <h3>Happy holidays from everyone at Ping Creates and all the best for 2022!</h3>
                            <button onClick={() => updateState(GameState.SELECT)}>
                                Start again
                            </button>
                        </div>
                    </motion.div>
                )}
            </>
    )
}

export default Video