
import ThemeSong from '../assets/sounds/theme.wav';
import useSound from 'use-sound';
import { useEffect, useState } from 'react';
import { useGameState, GameState } from '../context/game-state';

import Logo from '../assets/logo.png'

const Footer = () => {

    const gameState = useGameState();
    const [music, setMusic] = useState(true)
    const [play, { stop }] = useSound(ThemeSong, {
        volume: .1,
        soundEnabled: music,
        interrupt: true
    });
    

    useEffect(() => {
        if (gameState == GameState.SELECT)
            play();
        if (gameState == GameState.VIDEO) 
            stop()
    }, [gameState])

    useEffect(() => {
        if (!music) stop();
        else play();
    }, [music])

    return (
        <footer>
            <button onClick={() => { 
                    setMusic(!music);
                }}>
                    {music ? "Mute" : "Unmute"} Music
            </button>
            <h2><a href="https://pingcreates.com"><img src={Logo}/></a></h2>
        </footer>
    )
}

export default Footer
