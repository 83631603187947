
import SplashImage from '../../assets/splash.png';
import SplashPlace from '../../assets/splash_tiny.png';

import { useEffect} from 'react'

import { useSelectedCharacter, useUpdateCharacter, Characters } from '../../context/character-state';
import { motion, AnimateSharedLayout } from 'framer-motion';
import { useUpdateGameState, GameState } from '../../context/game-state';

import ProgressiveImage from '../ProgressiveImage';

import useSound from 'use-sound'
import SelectSound from '../../assets/sounds/select.wav';

const containerGroup = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren : .1
      }
    }
}

const characterGrid = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren : .05
      }
    },
    exit: {
        opacity: 1,
        transition: {
            staggerChildren : .03
        }
    }
}
  
const characterItem = {
    hidden: {
        y: 50,
        opacity: 0
    },
    show: {
        y: 0,
        opacity: 1
    },
    exit: {
        y: -50,
        opacity: 0
    }
}
  
const elementAnimation = {
    hidden: {
        y: 50,
        opacity: 0
    },
    show: {
        y: 0,
        opacity: 1
    },
    exit: {
        y: -50,
        opacity: 0
    }
}

const SelectScreen = () => {

    const [ play ] = useSound(SelectSound);

    const selectedCharacter = useSelectedCharacter();
    const updateCharacter = useUpdateCharacter();

    const updateGameState = useUpdateGameState();

    const clickCharacter = (character) => {
        play()
        updateCharacter(character)
    }

    useEffect(() => {
        console.log(selectedCharacter);
    }, [selectedCharacter])

    

    return (
        <motion.div
            variants={containerGroup}
            initial='hidden'
            animate='show'
            exit='exit'
            className="select-screen">
            <motion.h2 variants={elementAnimation}>Select your Fighter</motion.h2>
            <motion.h2 variants={elementAnimation} className="select-character">{selectedCharacter.name}</motion.h2>

           
                <motion.div
                    variants={characterGrid}
                    initial='hidden'
                    animate='show'
                    exit='exit'
                    className="character-grid">
                    {Object.values(Characters).map(character => (
                        <motion.div
                            variants={characterItem}
                            key={character.name}
                            className="character"
                            whileHover={{
                                scale: 1.05
                            }}
                            onClick={() => clickCharacter(character)}>
                            {selectedCharacter == character && (
                                <motion.div
                                    layoutId="selected"
                                    className="selected">
                                </motion.div>
                            )}
                            <div className="character-image">
                                {selectedCharacter == character ? (
                                    <ProgressiveImage placeholder={SplashPlace} image={character.headShot2}/>
                                ) : (
                                    <ProgressiveImage placeholder={SplashPlace} image={character.headShot}/>
                                )}
                                
                            </div>
                        </motion.div>
                    ))}
                </motion.div>
                <motion.button
                onClick={() => {
                    play();
                    updateGameState(GameState.VIDEO)
                }}
                    variants={elementAnimation}>
                Start
            </motion.button>
        </motion.div>
    )
}

export default SelectScreen
