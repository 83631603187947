import React, { useEffect }from "react";
import useProgressiveImg from "../hooks/useProgressiveImage";

const ProgressiveImage = ({ placeholder, image }) => {
    const [src, { blur }] = useProgressiveImg(placeholder, image);
    
    return (
        <img
            src={src}
            style={{
                clipPath: 'inset(0)',
                filter: blur ? "blur(20px)" : "none",
                transition: blur ? "none" : "filter 0.3s ease-out"
            }}
        />
    );
};

export default ProgressiveImage;