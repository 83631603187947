

import { GameStateProvider } from './context/game-state'
import { CharacterStateProvider } from './context/character-state'
import Game from './components/Game'
import { useEffect } from 'react'

import './styles/base.scss'
import DeviceOrientation, { Orientation } from 'react-screen-orientation'


function App() {

  useEffect(() => {
    console.log();
  }, [])
  
  return (
    <GameStateProvider>
      <CharacterStateProvider>
          <DeviceOrientation lockOrientation={'landscape'}>
                <Orientation orientation='landscape' alwaysRender={false}>
                    <Game/>
                </Orientation>
                <Orientation orientation='portrait' alwaysRender={false}>
                  <div className="rotation">
                    <p>Please rotate your device for a better experience.</p>
                  </div>
                </Orientation>
              </DeviceOrientation>  
      </CharacterStateProvider>
    </GameStateProvider>
  );
}

export default App;
