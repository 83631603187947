import { useGameState, useUpdateGameState, GameState } from "../context/game-state"
import { Characters, useSelectedCharacter, useUpdateCharacter } from '../context/character-state'
import { useEffect } from "react"
import Loading from "./stages/Loading"
import Splash from "./stages/Splash"
import SelectScreen from "./stages/SelectScreen"
import Video from "./stages/Video"
import Footer from "./Footer"

import Particle from './Particles';




import { AnimatePresence, AnimateSharedLayout } from "framer-motion"

const Game = () => {

    const gameState = useGameState()
    const updateState = useUpdateGameState();

    const selectedCharacter = useSelectedCharacter();

    const renderStage = () => {
        switch (gameState) {
            case GameState.SPLASH:
                return <Splash key="splash"/>
            case GameState.SELECT:
                return <SelectScreen key="select"/>
            case GameState.VIDEO:
            case GameState.END_GAME:
                return <Video key="video"/>

        }
    }

    useEffect(() => {
        console.log(window.screen);
    })

    return (
        <>
            {gameState === GameState.LOADING ? (
                <Loading />    
            ) : (
                <>
                    <div className="game-wrapper">
                            <AnimatePresence exitBeforeEnter>
                                <AnimateSharedLayout >
                                    {renderStage()}
                                </AnimateSharedLayout >
                            </AnimatePresence>
                        </div>
                    <Footer/>
                    <Particle />
                </>
                        
            )}
        </>
    )
}

export default Game
