
import { useState, useEffect } from 'react';
import useInterval from '../../hooks/useInterval';

import { useUpdateGameState, GameState } from '../../context/game-state'

const loadingMessages = [
    "BOOTING CHRISTMASCARD.exe",
    "LOADING SANTA",
    "FEEDING REINDEER",
    "BUILDING SNOWMAN",
    "LOADING PING_TEAM",
    "Starting game"
]

const Loading = () => {

    const updateGameState = useUpdateGameState();

    const tickStep = 150;
    const [messages, setMessages] = useState([]);
    const [messageStep, setMessageStep] = useState(0);

    useInterval(() => {

        if (messageStep % 4 == 0) {
            const newMessage = loadingMessages[messages.length];

            if (newMessage == null) {
                updateGameState(GameState.SPLASH)
                return;
            }
    
            setMessages([...messages, newMessage]);
            //console.log(messages);
        } else {
            const array = [...messages];
            array[array.length - 1] = array[array.length - 1] + ".";
            setMessages(array)
        }

        setMessageStep(messageStep + 1)

    }, tickStep);
    

    return (
        <div className="loading">
            {messages.map((string) => (
                <p key={string}>{string}</p>
            ))}
            
        </div>
    )
}

export default Loading
