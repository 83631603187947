import { createContext, useState, useContext } from 'react';

import JasonHeadshot from '../assets/characters/JASON/JASON_01.png';
import JasonHeadshot2 from '../assets/characters/JASON/JASON_02.png';

import JPHeadshot from '../assets/characters/JP/JP_01.png';
import JPHeadshot2 from '../assets/characters/JP/JP_02.png';

import NeilHeadshot from '../assets/characters/NEIL/NEIL_01.png';
import NeilHeadshot2 from '../assets/characters/NEIL/NEIL_02.png';

import NickHeadshot from '../assets/characters/NICK/NICK_01.png';
import NickHeadshot2 from '../assets/characters/NICK/NICK_02.png';

import ScottHeadshot from '../assets/characters/SCOTT/SCOTT_01.png';
import ScottHeadshot2 from '../assets/characters/SCOTT/SCOTT_02.png';

import SelinaHeadshot from '../assets/characters/SELINA/SELINA_01.png';
import SelinaHeadshot2 from '../assets/characters/SELINA/SELINA_02.png';

import SusanHeadshot from '../assets/characters/SUSAN/SUSAN_01.png';
import SusanHeadshot2 from '../assets/characters/SUSAN/SUSAN_02.png';

import TamiaHeadshot from '../assets/characters/TAMIA/TAMIA_01.png';
import TamiaHeadshot2 from '../assets/characters/TAMIA/TAMIA_02.png';


const CharacterStateContext = createContext();
const UpdateCharacterState = createContext();

const Characters = {
    CANDY_KANO: {
        name: "Candy Kano",
        headShot: NeilHeadshot,
        headShot2: NeilHeadshot2,
        headShopPlaceholder: "",
        videoURL: "https://youtu.be/ncDGSNpZ8As"
    },
    CHRISTMAS_BANDIT: {
        name: "The Christmas Bandit",
        headShot: SusanHeadshot,
        headShot2: SusanHeadshot2,
        headShopPlaceholder: "",
        videoURL: "https://youtu.be/a8yHUTXbSOw"
    },
    WAGNERTRON: {
        name: "Wagnertron",
        headShot: JasonHeadshot,
        headShot2: JasonHeadshot2,
        headShopPlaceholder: "",
        videoURL: "https://youtu.be/SwPKdyg5VGg"
    },
    SHERIFF_SNOW: {
        name: "Sheriff Snow",
        headShot: ScottHeadshot,
        headShot2: ScottHeadshot2,
        headShopPlaceholder: "",
        videoURL: "https://youtu.be/fPOFT_vReEI"
    },
    POLAR_BEAR: {
        name: "Polar Bear",
        headShot: NickHeadshot,
        headShot2: NickHeadshot2,
        headShopPlaceholder: "",
        videoURL: "https://youtu.be/gDOEYGH2j5k"
    },
    JAMMY_DODGER: {
        name: "Jammy Dodger",
        headShot: SelinaHeadshot,
        headShot2: SelinaHeadshot2,
        headShopPlaceholder: "",
        videoURL: "https://youtu.be/FsdntM1ei-w"
    },
    TWINKLE: {
        name: "Twinkle",
        headShot: TamiaHeadshot,
        headShot2: TamiaHeadshot2,
        headShopPlaceholder: "",
        videoURL: "https://youtu.be/gQFqmzoQh_I"
    },
    JP: {
        name: "Disco Dazzler",
        headShot: JPHeadshot,
        headShot2: JPHeadshot2,
        headShopPlaceholder: "",
        videoURL: "https://youtu.be/Ec8evQ9uhJE"
    },
}

const CharacterStateProvider = ({ children }) => {

    const [selectedCharacter, setSelectedCharacter] = useState(Characters.CANDY_KANO)

    const selectCharacter = (newState) => setSelectedCharacter(newState)
    
    return (
        <CharacterStateContext.Provider value={selectedCharacter}>
            <UpdateCharacterState.Provider value={selectCharacter}>
                {children}
            </UpdateCharacterState.Provider>
        </CharacterStateContext.Provider>)
}

const useSelectedCharacter = () => {
    const context = useContext(CharacterStateContext);

    if (context === undefined) 
        throw new Error('useGameState must be used within the GameContextProvider');
    
    return context;
}

const useUpdateCharacter = () => {
    const context = useContext(UpdateCharacterState);

    if (context === undefined) 
        throw new Error('useUpdateGameStatus must be used within the UpdateGameProvider');
    
    return context;
}

export {
    CharacterStateProvider,
    useSelectedCharacter,
    useUpdateCharacter,
    Characters
}