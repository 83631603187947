import SplashImage from '../../assets/splash.png';
import { useUpdateGameState, GameState } from '../../context/game-state'
import SelectSound from '../../assets/sounds/select.wav';

import SplashTiny from '../../assets/splash_tiny.png'
import SplashLarge from '../../assets/splash.png'

import ProgressiveImage from '../ProgressiveImage'

import { motion } from 'framer-motion';
import useSound from 'use-sound'

const elementAnimation = {
    hidden: {
        y: 50,
        opacity: 0
    },
    show: {
        y: 0,
        opacity: 1
    },
    exit: {
        y: -25,
        opacity: 0
    }
}

const containerGroup = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren : .1
      }
    },
    exit: {
        transition: {
            staggerChildren : .05
        }
    }
}

const Splash = () => {

    const [ play ] = useSound(SelectSound);


    const updateState = useUpdateGameState();

    return (
        <motion.div
            variants={containerGroup}
            initial="hidden"
            animate="show"
            exit="exit"
            className="splash">
            <motion.div variants={elementAnimation} className="splash-image">
                <ProgressiveImage placeholder={SplashTiny} image={SplashLarge}/>
            </motion.div>
            
            <motion.div variants={elementAnimation} className="btn-wrapper">
                <button onClick={() => {
                    play()
                    updateState(GameState.SELECT)
                }}>Play Now</button>
            </motion.div>
        </motion.div>
    )
}

export default Splash
